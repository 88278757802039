import { render, staticRenderFns } from "./JTime.vue?vue&type=template&id=ddc303a0&scoped=true&"
import script from "./JTime.vue?vue&type=script&lang=js&"
export * from "./JTime.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ddc303a0",
  null
  
)

export default component.exports