import { render, staticRenderFns } from "./HeaderNotice.vue?vue&type=template&id=72672424&scoped=true&"
import script from "./HeaderNotice.vue?vue&type=script&lang=js&"
export * from "./HeaderNotice.vue?vue&type=script&lang=js&"
import style0 from "./HeaderNotice.vue?vue&type=style&index=0&lang=css&"
import style1 from "./HeaderNotice.vue?vue&type=style&index=1&id=72672424&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72672424",
  null
  
)

export default component.exports