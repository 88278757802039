import { render, staticRenderFns } from "./JVxeImageCell.vue?vue&type=template&id=5bb61b2e&scoped=true&"
import script from "./JVxeImageCell.vue?vue&type=script&lang=js&"
export * from "./JVxeImageCell.vue?vue&type=script&lang=js&"
import style0 from "./JVxeImageCell.vue?vue&type=style&index=0&id=5bb61b2e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb61b2e",
  null
  
)

export default component.exports