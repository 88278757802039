import { render, staticRenderFns } from "./JSuperQuery.vue?vue&type=template&id=35d1fb3b&scoped=true&"
import script from "./JSuperQuery.vue?vue&type=script&lang=js&"
export * from "./JSuperQuery.vue?vue&type=script&lang=js&"
import style0 from "./JSuperQuery.vue?vue&type=style&index=0&id=35d1fb3b&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35d1fb3b",
  null
  
)

export default component.exports