import { render, staticRenderFns } from "./JVxePopupCell.vue?vue&type=template&id=9954586e&scoped=true&"
import script from "./JVxePopupCell.vue?vue&type=script&lang=js&"
export * from "./JVxePopupCell.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9954586e",
  null
  
)

export default component.exports